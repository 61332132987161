<template>
  <div class="terms-container">
    <div class="terms html-holder">
      <div class="container">
        <h2 class="second-title">Privacy policy</h2>
        <h2 class="second-title">SGComputers</h2>
        <p class="text-item">Effective August 01, 2023</p>
        <p class="text-item">
          If you visit this page, it means that you care about your data safety
          and protection. In this Privacy Policy, we explain what and how we
          use:
        </p>
        <ul class="disc">
          <li>Reasons and means of personal data acquisition</li>
          <li>Rules and methods of personal data processing</li>
          <li>Your personal data protection rights and their fulfillment</li>
        </ul>
        <p class="text-item">
          This Privacy Policy applies to all games, web services, and related
          services provided by SGComputers (such as the customer support,
          forums), referred to jointly herein as Services.
        </p>
        <p class="text-item">
          This policy is subject to change and update. If we make any
          significant changes to our policy, we may notify the users in the
          Services. If the user continues using the Services after the update,
          it means they agree with the revised version. We strongly recommend
          you to visit the page from time to time (see the date of the latest
          update at the top of the page).
        </p>
        <p class="text-item">
          This Privacy Policy is designed to meet the requirements of the
          following legislative acts:
        </p>
        <ul class="disc">
          <li>
            General Data Protection Regulation of the European Union (“GDPR”);
          </li>
          <li>
            Children's Online Privacy Protection Act of the United States
            (“COPPA”);
          </li>
        </ul>

        <h2 class="second-title">How to contact us</h2>
        <p class="text-item">
          If you have questions about data protection, we recommend you to
          contact us thus we can answer you.
        </p>

        <p class="text-item">
          Name of the company collecting the personal data:
          SGComputersundefined+
          <br />
          Address: 85000, Bar, Montenegro, Susanj b.b.undefined+
          <br />
          Email: sgcomputersme@gmail.com
        </p>
        <h2 class="second-title">Purposes of the Privacy Policy</h2>
        <ul class="disc">
          <li>Protection of the users’ rights</li>
          <li>Safety and protection from fraud when using the</li>
          <li>
            Ensuring correct performance and improving the quality of the
            Services
          </li>
          <li>Providing equal and fair game rules</li>
        </ul>
        <h2 class="second-title">Reasons and Means to Collect Data</h2>
        <h3 class="third-title">Data you Submit Directly</h3>
        <ul class="disc">
          <li>
            Contact information (name, email address). Usually submitted when
            contacting the customer support or signing up to our forum. We
            require this data to make sure that we can get in touch with you and
            help resolve the issues you have encountered.
          </li>
          <li>
            Nickname, avatar, password, and other data from the game profile or
            other profiles. Required to identify players and ensure proper
            functioning of the Services.
          </li>
          <li>
            Your messages related to the Services: chat messages, posts in
            official communities, on the forums, and on the mobile app
            distribution platforms (such as Google Play, App Store) as well as
            messages sent to the support team and our games reviews. This data
            is used to provide the Services and monitor their quality.
          </li>
          <li>
            Other data you choose to submit, such as data required for account
            verification if you lose access to your account as well as for
            messaging and participating in online or offline events hosted by
            us.
          </li>
          <li>
            Public data. Public data is the information that is published and is
            available on the Internet. We may collect and analyze this data for
            the purpose of improving the quality of the Services.
          </li>
        </ul>
        <h3 class="third-title">Data Collected Automatically</h3>
        <ul class="disc">
          <li>
            Information provided in your game profile, as well as information
            about using the Services. Used to ensure proper functioning of the
            Services, personification of the Services, game analytics, recording
            in-game events, achievements, and progress.
          </li>
          <li>
            Identification data for the devices used to launch the game, such as
            ip address, device id, advertising id, imei number, general location
            data. Used to ensure safety and fraud countermeasures when needed.
          </li>
          <li>
            Technical information about devices (e.g. device name, operating
            system, language, browser type, additional plugin versions). Used to
            monitor the quality of the Services.
          </li>
          <li>
            Cookie files and other similar technologies (e.g. marketing
            beacons). Used for personalization, use analysis, and ensuring the
            safety of the Services. You may modify or disable these technologies
            in browsers or the corresponding device settings. However, we cannot
            guarantee that our Services will operate properly when
            personalization technologies are disabled and therefore do not
            recommend that you disable them completely.
          </li>
        </ul>
        <h3 class="third-title">Data Provided by our Partners</h3>
        <ul class="disc">
          <li>
            Data from third-party services if you choose to link them to our
            Services (Facebook profile, Google, Apple services). This data is
            used for various purposes, such as recording in-game progress,
            integration with other game services, integration with mobile
            platform gaming profiles, and for game account security.
          </li>
          <li>
            Platform data (e.g. Google, Apple) required for payment verification
            and combating fraud. We do not gain access to your payment
            information and transaction history from the platforms. All payments
            are carried out via secure protocols on the platform’s side.
          </li>
          <li>
            Demographic data, analytics, and other information required for
            personalizing ads and improving the quality of our Services.
          </li>
        </ul>
        <p class="text-item">
          These are the main data collection reasons for particular data
          categories. We may also analyze, profile, and segment all the data
          listed above.
        </p>
        <h2 class="second-title">Who Can Handle Your Data?</h2>
        <ul class="disc">
          <li>
            SGComputers employees, representatives of subsidiaries and
            affiliated companies.
          </li>
          <li>
            Other users of the Services: For instance, your game profile data
            (nickname, avatar), chat messages, and your game progress can be
            accessed by other players and can be a part of the Services.
          </li>
          <li>
            Partners: mobile platforms and social networks, hardware providers,
            providers of user support, moderation, QA, community management,
            data storage, data analytics, advertisement, cross-promotion tools,
            logistics companies. We work with partners all over the world. These
            partners only process data as instructed. Partners included but not
            limited to:
          </li>
        </ul>
        <ul class="disc">
          <li>Game publishing platforms:</li>
          <ul>
            <li>https://play.google.com</li>
            <li>https://www.apple.com/lae/ios/app-store/</li>
          </ul>
        </ul>
        <ul class="disc">
          <li>Analytics:</li>
          <ul>
            <li>https://analytics.google.com/</li>
            <li>https://unity.com/solutions/analytics</li>
          </ul>
        </ul>
        <ul class="disc">
          <li>Software services:</li>
          <ul>
            <li>https://unity3d.com/</li>
          </ul>
        </ul>
        <ul class="disc">
          <li>Data storage:</li>
          <ul>
            <li>http://hetzner.de/</li>
          </ul>
        </ul>
        <ul class="disc">
          <li>Marketing and advertisement:</li>
          <ul>
            <li>https://unity3d.com/unity/features/ads</li>
            <li>https://searchads.apple.com</li>
            <li>https://ads.google.com</li>
          </ul>
        </ul>
        <ul class="disc">
          <li>
            Public authorities: We reserve the right to disclose personal data
            legitimately requested by regulatory agencies or auditors.
          </li>
          <li>
            Anonymous data: We reserve the right to disclose anonymous and
            aggregated data based on your personal data to our partners. This
            data cannot be used to identify you or linked to you directly or
            indirectly (e.g. game progress stats for users from a particular
            country).
          </li>
          <li>
            We may also process personal data we receive from other companies as
            an operator. In such an event the data is handled in strict
            accordance with the contract with our partners and in accordance
            with the EU principles for personal data protection.
          </li>
          <li>
            As an international company, SGComputers may disclose your personal
            data to parties outside the EU. We guarantee compliance with the EU
            rules and methods of data protection in regard to these parties.
          </li>
        </ul>
        <h2 class="second-title">Your rights and options</h2>
        <ul class="disc">
          <li>
            We give you the right to correct, delete, dispute and restrict use
            of, and access the data we store, as well as other rights guaranteed
            by the EU laws based on your substantiated request.
          </li>
          <li>
            You have the right to a copy of your personal information to be
            supplied to you in an electronic format upon request.
          </li>
          <li>
            Opting out of personalized ads: Many ad tools and services utilize
            so-called personalized ads that take into account your personal
            interests and experience. You may opt out of personalized ads in
            browser and/or device settings. Please note that we cannot guarantee
            that our Services will function properly in this case.
          </li>
          <li>
            We will do everything in our power to promptly resolve your request.
            We reserve the right to ask for identity authentication before
            fulfilling your request.
          </li>
        </ul>
        <h2 class="second-title">Data Storage and Protection</h2>
        <ul class="disc">
          <li>
            In order to help ensure a secure and safe player experience, we are
            continuously developing and implementing administrative, technical
            and physical security measures to protect your data from
            unauthorized access or against loss, misuse or alteration.
          </li>
          <li>
            Personal data is usually kept for the duration of using the game
            account or longer, as per our legal interests. We will for example
            periodically de-identify unused game accounts and we regularly
            review and anonymize unnecessary data.
          </li>
          <li>
            Note that if you ask us to remove your personal data, we will retain
            your data as necessary for our legitimate business interests, such
            as to comply with our legal obligations, resolve disputes, and
            enforce our agreements. Requests to delete personal data are
            resolved in up to 30 calendar days.
          </li>
          <li>
            Your personal data is deleted or anonymized automatically after two
            years of account inactivity. Certain data such as payment data may
            be stored for periods longer than this in accordance with applicable
            accounting laws.
          </li>
        </ul>
        <h2 class="second-title">Age Restrictions</h2>
        <ul class="disc">
          <li>
            We do not knowingly collect or solicit personal data about or direct
            or target interest-based advertising to anyone under the age of 13
            or knowingly allow such persons to use our Services. If you are
            under 13, please do not send any data about yourself to us, such as
            your name, address, telephone number, or email address. No one under
            the age of 13 may provide any personal data. If we learn that we
            have collected personal data about a child under age 13, we will
            delete such data without delay. If you believe that we might have
            any data from or about a child under the age of 13, please contact
            us.
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PrivacyView",
  data() {
    return {
      menuOpen: false,
    };
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
  },
};
</script>

<style>
.title {
  color: #444;
  margin: 10px 0;
}

.effective-date {
  font-weight: bold;
}

.bullet-list {
  list-style: disc;
  padding-left: 20px;
}

p {
  margin: 10px 0;
}

/* Responsive styles */
@media (max-width: 600px) {
  .policy-container {
    padding: 10px;
  }
}
</style>
