<template>
  <div class="main-container">
    <img src="@/assets/img/logo.png" alt="SG Computers Logo" class="logo" />
    <h1>SGComputers</h1>
    <p>Creating simple games for fun</p>
    <div style="display: inline-block">
      <a
        class="linl-app"
        href="https://play.google.com/store/apps/dev?id=9130536656606383778"
        target="_blank"
      >
        <svg
          width="15"
          height="16"
          viewBox="0 0 15 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.952353 0.937012C0.943218 0.98069 0.934082 1.02437 0.934082 1.07176V15.0954L8.02256 8.00693L0.952353 0.937012Z"
            fill="white"
          />
          <path
            d="M1.01978 15.4713C1.18792 15.7631 1.57561 15.8958 1.89991 15.7043L10.5894 10.5739L8.25328 8.23755L1.01978 15.4713Z"
            fill="white"
          />
          <path
            d="M10.54 5.48957L1.89367 0.516791C1.61904 0.35892 1.30272 0.431718 1.1106 0.633838L8.25332 7.77656L10.54 5.48957Z"
            fill="white"
          />
          <path
            d="M14.0002 7.47932L10.8331 5.65796L8.48389 8.00718L10.8796 10.4029L14.0065 8.55701C14.4273 8.31949 14.4239 7.71199 14.0002 7.47932Z"
            fill="white"
          />
        </svg>
        <div>
          <div class="link-app-text mini">Get it on</div>
          <div class="link-app-title mini">Google Play</div>
        </div></a
      >
    </div>
    <p>
      Our goal is to create simple games for fun with strict advertising
      policies. In any case, we understand how annoying interstitial advertising
      can be, so we decided not to use it. Rewarded Ads Only - Here you can
      choose when you want to see ads. And, sometimes, banner advertising, but
      it should not interfere with the gameplay. So just play and have fun, and
      not be annoyed by constantly popping up ads :)
    </p>

    <h2 id="games">Our Games</h2>
    <ul class="games-list">
      <li>
        <a
          href="https://play.google.com/store/apps/details?id=com.SGComputers.balloons"
        >
          <img
            src="@/assets/img/IconBalloonsPop.png"
            width="32"
            height="32"
            alt="Balloons Pop: Secret Garden Icon"
          />
          &nbsp;Balloons Pop: Secret Garden
        </a>
      </li>
      <li>
        <a
          href="https://play.google.com/store/apps/details?id=com.com.SGComputers.escape.escape"
        >
          <img
            src="@/assets/img/IconBlockPuzzle.png"
            width="32"
            height="32"
            alt="Block Puzzle: Unlock & Escape Icon"
          />
          &nbsp;Block Puzzle: Unlock & Escape
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "MainView",
};
</script>

<style scoped>
.main-container {
  background-image: url("@/assets/img/background.png");
  background-size: cover;
  background-position: center;
  color: white;
  padding: 20px;
  text-align: center;
  min-height: 100vh;
}

.logo {
  max-width: 200px;
  margin: 20px 0;
}

h1,
h2 {
  color: black;
  margin: 10px 0;
}

h1 {
  font-size: 2.5em;
}

h2 {
  font-size: 2em;
  margin-top: 40px;
}

p {
  color: black;
  font-size: 1.2em;
  margin: 10px auto;
  max-width: 800px;
}

.games-list {
  list-style-type: none;
  padding: 0;
}

.games-list li {
  margin: 15px 0;
}

.games-list a {
  text-decoration: none;
  font-size: 1.2em;
}

.games-list a:hover {
  text-decoration: underline;
}

/* Responsive styles */
@media (max-width: 600px) {
  h1 {
    font-size: 2em;
  }

  p {
    font-size: 1em;
  }
}

.linl-app {
  color: white !important;
  width: 200px !important;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 9px 15px;
  background: #373737;
  border-radius: 28px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
}

.linl-app svg {
  margin-right: 6px;
}

@media (min-width: 834px) {
  .linl-app svg {
    width: 10px;
    height: 10px;
  }
}

@media (min-width: 1120px) {
  .linl-app svg {
    width: 20px;
    height: 20px;
  }
}

@media (min-width: 1400px) {
  .linl-app svg {
    width: 23px;
    height: 23px;
  }
}

.linl-app.mini {
  padding: 5px 10px;
}

@media (min-width: 834px) {
  .linl-app.mini {
    padding: 4px;
  }
}

@media (min-width: 1120px) {
  .linl-app.mini {
    padding: 8px;
  }
}

@media (min-width: 1400px) {
  .linl-app.mini {
    padding: 11px;
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
    opacity: 40%;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
  }
  .linl-app.mini:hover {
    opacity: 1;
    -webkit-filter: grayscale(0);
    filter: grayscale(0);
  }
}

.linl-app.mini.not-active:hover {
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  opacity: 40%;
  cursor: unset;
}
</style>
