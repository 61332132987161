<template>
  <header class="header">
    <nav class="nav">
      <router-link to="/" class="nav-item">SG Computers</router-link>
      <div class="nav-item right">
        <div class="link">
          <router-link to="/privacy" style="color: white"
            >Privacy Policy</router-link
          >
        </div>
        <div class="link">
          <router-link to="/terms" style="color: white"
            >Terms of Use</router-link
          >
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
export default {};
</script>

<style scoped>
.header {
  background-color: #2c3e50;
  padding: 10px 20px;
  color: white;
}
.link {
  color: white;
  margin-left: 20px;
  margin-right: 20px;
}
.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-item {
  color: white;
  text-decoration: none;
  margin: 0 15px;
  display: flex;
}

.nav-item:hover {
  text-decoration: underline;
}

@media (max-width: 600px) {
  .nav {
    flex-direction: column;
    align-items: center;
  }

  .nav-item {
    margin: 10px 0;
  }
}
</style>
