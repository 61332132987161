<template>
  <div class="terms-container">
    <div class="terms html-holder">
      <div class="container">
        <h2 class="second-title">Terms of Service</h2>
        <h2 class="second-title-text">1. Introduction</h2>
        <p class="text-item">
          We, SGComputers (referred to as «we», «us», «our») are glad to welcome
          you on our website. We sincerely hope that you will get the best user
          experience playing our games and using our service. Before using our
          services and playing games developed by our company, please read these
          Terms of Service and the Privacy Policy to understand clearly all you
          rights and obligations when you use our products or services.
        </p>
        <h2 class="second-title-text">2. Agreement to Terms</h2>
        <p class="text-item">
          These Terms of Service constitute a legally blinding agreement made
          between you and SGComputers, concerning your access to and use of our
          website as well as any of our applications/games and/or our other
          services, media channels.
        </p>
        <p class="text-item">
          You must clearly understand that playing our games or using our
          services you agree to the Terms of Service (referred to as
          «agreement», «Terms») as well as to the Privacy Policy and accept them
          whether you are a registered or a non-registered user. You agree that
          by accessing the website you have read, understood and agree to be
          bound by these Terms of Service. If you do not accept these Terms of
          Service you are prohibited from using our services and/or play our
          games. We reserve the right to change this agreement from time to time
          adding, editing or deleting the information. It is your responsibility
          to review periodically these Terms to stay informed of any upgrades.
        </p>
        <h2 class="second-title-text">3. Our games and services</h2>
        <p class="text-item">Website: https://sgcomps.xyz</p>
        <p class="text-item">Games: see section Our Games at Website.</p>
        <p class="text-item">
          We reserve the right to change the list any time, adding, editing or
          deleting the information about products. The last update date will be
          indicated in the end of the agreement.
        </p>
        <h2 class="second-title-text">4. Access</h2>
        <p class="text-item">
          In order to access and use our service you must register and log in
          into your account with a platform/mobile applications market where our
          games are available. Please do not share you account information to
          avoid that others can access you account. We reserve the right to
          block your account without notice.
        </p>
        <h2 class="second-title-text">5. Intellectual properties</h2>
        <p class="text-item">
          The website, all source code, database, website design, audio, video,
          text, photographs, graphics, reviews (collectively, the Content) and
          trademarks, logos contained therein, the games and is a property of
          and controlled by or licensed to SGComputers, and are protected by
          copyright and trademarks laws. No the website, the Content and logos
          in whole or in part may be copied, reproduced, republished, posted,
          distributed, licensed, sold or otherwise use for any commercial
          purpose without our written permission.
        </p>
        <h2 class="second-title-text">
          6. User representations and warranties
        </h2>
        <p class="text-item">
          By using our website, services, applications and games you warrant the
          following: <br />
          - you have the legal capacity and agree to comply to all the
          conditions written in these Terms. <br />
          - you will not use this site for any illegal and unauthorized purposes
          <br />
          - all the personal information you will provide will be true and
          current. Also you will maintain the accuracy of the information.
        </p>
        <h2 class="second-title-text">7. Reviews</h2>
        <p class="text-item">
          We always do our best and try to provide the best quality of our
          applications/games and services. In order to know that you may or we
          may ask you to leave your review or ratings on the website or in the
          page of our application/game in the market where it is available. When
          posting a review you must comply with the following: <br />- your
          reviews should not contain any abusive, racist or hate language
          <br />- your reviews are not false and do not mislead or confuse other
          users <br />- you are not affiliated by competitors to post negative
          reviews <br />
          Following the criteria above we may accept or not, reject or delete
          reviews. Also we are not obliged to screen absolutely all reviews. All
          the reviews posted on our website and/or related to our applications,
          games and services as well as information of the person who posted are
          also a part of the Content and cannot be copied or used for any
          illegal or commercial purposes
        </p>
        <h2 class="second-title-text">8. Advertisement</h2>
        <p class="text-item">
          We may provide certain areas of our website for advertisers to display
          their advertisement. If you are an advertiser you take fully
          responsibility of the content of advertisement you place on the
          website. You as an advertiser warrant that you are licensed and
          possess all the rights and authority to place the advertisement
          including, but not limited to, intellectual and publicity rights.
        </p>
        <h2 class="second-title-text">9. Management</h2>
        <p class="text-item">
          We reserve the right, but not the obligation, to monitor the website
          and act appropriately within legal boundaries against any violations
          of these Terms. We reserve the right, in our sole discretion and with
          no limits, to refuse, restrict or limit your access to our website
          and/or applications, games and/or services if we decide that any of
          your actions violate the rights of intellectual property, other of our
          and/or other users rights, laws, legal acts.
        </p>
        <h2 class="second-title-text">10. User data</h2>
        <p class="text-item">
          We will collect and store certain that you submit on the website for
          the purposes of managing. That is your sole responsibility for all the
          data you submit. More information about what data, how and why we
          collect you may read in the Privacy Policy.
        </p>
        <h2 class="second-title-text">11. Termination</h2>
        <p class="text-item">
          These Terms, as well as the Privacy Policy, will effect while you use
          our website, applications/games or services. We reserve the right to
          deny access to and use of our services, applications and website
          without any notice and for any or no reason in our sole discretion.
          Also we may delete your account with all the related information, any
          content such as reviews or other information you posted without any
          notice at any time. If we terminate your account for any reason, you
          are prohibited to create a new account using the same or a fake name.
          In this case please contact us to understand the reason. In addition,
          if we terminate or suspend your account we reserve the right to
          appropriate legal actions.
        </p>
        <h2 class="second-title-text">12. Governing law</h2>
        <p class="text-item">
          These Terms of Service as well as the Privacy Policy are governed by
          the laws of Montenegro.
        </p>
        <h2 class="second-title-text">13. Contacts</h2>
        <p class="text-item">
          If you have any questions, requests or suggestions, please contact us
          at e-mail: sgcomputersme@gmail.com.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TermsView",
  data() {
    return {
      menuOpen: false,
    };
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
  },
};
</script>

<style>
.terms-container {
  padding: 20px;
  color: #333;
}

.title,
.subtitle {
  color: #444;
  margin: 10px 0;
}

p {
  margin: 10px 0;
}

/* Responsive styles */
@media (max-width: 600px) {
  .terms-container {
    padding: 10px;
  }
}
</style>
