import { createRouter, createWebHashHistory } from "vue-router";
import MainView from "../views/MainView.vue";
import PrivacyView from "@/views/PrivacyView.vue";
import TermsView from "@/views/TermsView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: MainView,
  },
  {
    path: "/privacy",
    name: "privacy",
    component: PrivacyView,
  },
  {
    path: "/terms",
    name: "terms",
    component: TermsView,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
