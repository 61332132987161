<template>
  <footer class="footer">
    <p>SGComputers D.O.O.</p>
    <p class="year">{{ currentYear }}</p>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>

<style scoped>
.footer {
  background-color: #2c3e50;
  color: white;
  text-align: center;
  padding: 20px 0;
  position: relative;
  bottom: 0;
  width: 100%;
}

.year {
  font-size: 0.8em;
  margin-top: 5px;
}
</style>
